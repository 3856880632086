import './index.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import {
  Signup, SignIn, Forgotpassword, Checkmail, SetNewPassword, PasswordResetSuccessfull, EmailVerification, FinalizingSetup
} from './Pages/Authpages';

import {Home,Intermediate, Advanced, Beginners, Quiz, Dashboard, Leaderboard, Settings, Support, Progress, Earnings, Quizzes, Cardcheckout, Cryptocheckout, BeginnersCourse, Intermediate_preview } from './Pages/DashboardPages';
import BottomNav from './components/BottomNav';
import Completed from './components/Completed';
import Sidebar from './components/Sidebar';
import './fonts/GeneralSans-Regular.woff'
import LandingPage from './Pages/Landingpage/Pages/Waitlist'
import './fonts/FontSpring1.otf'
import './fonts/FontSpring2.otf'

function App() {
  const location = useLocation();

  // Function to check if the current route should hide the sidebar
  const shouldHideSidebar = () => {
    const hiddenRoutes = [
      '/',
      '/signin',
      '/signup',
      '/forgotpassword',
      '/check-your-mail',
      '/set-new-password',
      '/password-reset-successfull',
      '/email-verification',
      '/finalizing-setup',
      '/welcome',
      '/completed',
      '/questions'
    ];
    return hiddenRoutes.includes(location.pathname);
  };

  return (
    <div className='lg:flex '>
      {!shouldHideSidebar() && <Sidebar />}
      {!shouldHideSidebar() && <BottomNav/>}

      <div className='w-full bg-dashboardbg  font-generalsans'>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          
        </Routes>
      </div>
      
      
      
      
    </div>

  );
}

export default App;
